:root {
  --primarycolor: #040311;
  --secondarycolor: #59eacd;
  --textcolor: #dbdbe7;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: 'poppins';
  scroll-behavior: smooth;
}

h1 {
  font-size: 100px;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-size: 14px;
  padding: 5px 0px 5px 0px;
}

body {
  margin: 0;--tw-bg-opacity: 1;
  color: var(--textcolor);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background: linear-gradient(-45deg, var(--primarycolor), #354c7b);
	background-size: 150% 150%;
	animation: gradient 10s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 100% 100%;
	}
	50% {
		background-position: 50% 50%;
	}
	100% {
		background-position: 100% 100%;
	}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.name {
  font-size: 30px;
  height: fit-content;
  width: fit-content;
  font-family: heyAugust;
  text-align: left;
  display: inline-block;
  padding: 5px;
  padding-left: 10px;
}

.title {
  width: fit-content;
  margin-left: 10px;
  margin-right: auto;
}

.content {
  margin-left: 10%;
  margin-right: 10%;
}

btn {
  transition: background-color .4s ease-in-out;
  transition: color .4s ease-in-out;
  color: var(--secondarycolor);
  padding: 5px 10px;
  border: 2px solid var(--secondarycolor);
  border-radius: 10px;
  display: inline-block;
}

btn:hover {
  background-color: var(--secondarycolor);
  color: var(--primarycolor);
}

.sample-project, .experience-block {
  padding: 20px;
  /* border-radius: 3px; */
  border-top: 1px solid #7b7b8f00;
}

.about {
  padding-top: 5px;
  padding: 20px;
}

.sample-project:hover, .experience-block:hover {
  opacity: 1.0;
  /* border-top: 1px solid #7b7b8f; */
  background-color: #ffffff07;
  transition: all .5s ease;
}

.skill {
  border: 2px solid var(--secondarycolor);
  color: var(--secondarycolor);
  margin: 0px 5px 0px 5px;
  padding: 2px 10px 2px 10px;
  border-radius: 10px;
  font-size: 12px;
}

.svg-link {
  margin: 5px
}

.svg-link:hover .path-main {
  fill: #dbdbe7;
}

.section {
  opacity: .5;
}

.section:hover {
  opacity: 1;
  transition: all .5s ease;
}

.footer {
  font-size: 10px;
  margin: 40px;
  text-align: right;
}

.external-link {
  vertical-align: middle;
  margin-right: 10px;
}

.external-link:hover {
  text-decoration: underline;
  text-decoration-color: var(--secondarycolor);
}

#nav-link {
  opacity: .5;
  font-size: 20px;
  transition: all .5s ease;
}

#nav-link.active {
  opacity: 1;
  font-size: 30px;
}