@font-face {
  font-family: "heyAugust";   /*Can be any text*/
  src: local("heyAugust"),
    url("./fonts/heyAugust.ttf") format("truetype");
}

@font-face {
  font-family: "poppins";   /*Can be any text*/
  src: local("poppins"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

/* Stuff that came with React */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 5s;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
